import React, { Suspense, useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useMatch,
} from 'react-router-dom-v5-compat'
import { wfetch, rejectResponseWithErrorData } from '@wf-mfe/api'
import { useCurrentUser } from '@wf-mfe/auth'
import { ErrorBoundary } from '@wf-mfe/logger'
import { getMessageKeyForObjCode } from '@wf-mfe/navigation'
import { AccessLevel } from 'workfront-objcodes'

import './styles.css'
import { HeaderEditingRouteObjectName } from './HeaderEditingRouteObjectName.js'
import NoAccess from './NoAccess.js'
import NotFound from './NotFound.js'
import { PageContent } from './PageContent.js'
import { useGrantPermissionSuccess } from './useGrantPermissionSuccess.js'
import {
  PageContentSlot,
  PageHeaderSlot,
  PageToolbarSlot,
} from '../single-spa-slots-react/single-spa-slots-react.js'

const STARTING_PATH_OPTIONAL_ID_REGEX = /\/[^/]*(\/[a-f0-9]{32})?/
const VALID_ID_REGEX = /(\d|[a-f0-9]{32})/

function InnerPage(props) {
  const {
    Header,
    additionalAccessCheckAction,
    fetchPermissions,
    fields = [],
    hasToolbar,
    mfeName,
    objCode,
    path,
    styles,
    toolbarLeft,
    toolbarRight,
  } = props

  const basePath = path

  const navigate = useNavigate()
  const location = useLocation()
  const match = useMatch(`${path}/*`)

  // We don't want to pass `children` down the component tree,
  // they change their reference and cause re-renders.
  const { children, ...propsWithoutChildren } = props

  const currentUser = useCurrentUser()
  const [obj, setObj] = useState({})
  useGrantPermissionSuccess({ obj })
  const [noAccess, setNoAccess] = useState()
  const [notFound, setNotFound] = useState(false)
  const [errorMsg, setErrorMsg] = useState()

  useEffect(() => {
    if (errorMsg) {
      throw new Error(errorMsg)
    }
  }, [errorMsg])

  const objID = match?.params?.ID
  let fieldsToFetch = [...fields]
  if (fetchPermissions) {
    fieldsToFetch.push('permissions')
  }

  const fieldsString = fieldsToFetch.join(',')

  const originalPathname = useRef(location.pathname)

  useEffect(() => {
    if (location.pathname !== originalPathname.current) {
      setNotFound(false)
      setNoAccess(null)
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname === '/report/view/fromEmail') {
      const ID = new URLSearchParams(location.search).get('ID')

      queueMicrotask(() => {
        navigate(`/report/${ID}/details`, { replace: true })
      })
    }
  }, [location, navigate])

  useEffect(() => {
    let controller1 = new AbortController()
    let controller2 = new AbortController()

    if (objCode && objID) {
      if (!VALID_ID_REGEX.test(objID)) {
        setNotFound(true)
        return
      }

      ;(async () => {
        try {
          const reqPromises = [
            wfetch(
              `/attask/api-internal/${objCode}/${objID}?fields=${fieldsString}`,
              { signal: controller1.signal },
              { handleErrorResponse: rejectResponseWithErrorData }
            ),
          ]

          if (additionalAccessCheckAction) {
            reqPromises.push(
              wfetch(
                `/attask/api-internal/${AccessLevel}?method=PUT&action=hasAccess&objID=${objID}&objCode=${objCode}&actionType=${additionalAccessCheckAction}`,
                { signal: controller2.signal },
                { handleErrorResponse: rejectResponseWithErrorData }
              )
            )
          }

          const [obj, hasAccess] = await Promise.all(reqPromises)

          if (reqPromises.length === 2 && !hasAccess) {
            setNoAccess({ requestAccess: false })
          } else {
            setObj(obj)
          }
        } catch ({ errorData, response }) {
          const message = errorData?.error?.message

          switch (response?.status) {
            case 404:
            case 422:
              setNotFound(true)
              break
            case 403:
              setNoAccess({
                noAccessMessage: message,
                requestAccess: true,
              })
              break
            default:
              setErrorMsg(message || `Problem loading ${objCode} ${objID}`)
          }
        }
      })()
    }

    return () => {
      controller1.abort()
      controller2.abort()
    }
  }, [
    objCode,
    objID,
    fieldsString,
    fetchPermissions,
    additionalAccessCheckAction,
  ])

  if (notFound) {
    return (
      <PageContentSlot mfeName={mfeName}>
        <NotFound objectMessageKey={getMessageKeyForObjCode(objCode || '')} />
      </PageContentSlot>
    )
  }

  if (noAccess) {
    return (
      <PageContentSlot mfeName={mfeName}>
        <NoAccess
          {...propsWithoutChildren}
          requestAccess={noAccess.requestAccess}
          message={noAccess.noAccessMessage}
          objectMessageKey={getMessageKeyForObjCode(objCode || '')}
        />
      </PageContentSlot>
    )
  }

  const baseComponentRemountKey = location?.pathname?.match(
    STARTING_PATH_OPTIONAL_ID_REGEX
  )[0]

  const navConfig = normalizeNavConfigProp(props)
  let switchChildrenPathsAndKeys
  let defaultChildPath

  if (navConfig) {
    switchChildrenPathsAndKeys = Object.entries(navConfig)
      .map(([key, { subPath, getPageContent }]) => {
        if (!getPageContent) {
          return null
        }
        const child = getPageContent({ ...props, pageMounted: true })
        const childPath = subPath ?? child.props.tabPath
        return [child, childPath ?? path, key]
      })
      .filter(Boolean)
  } else if (typeof children !== 'function') {
    const childArray = React.Children.toArray(children)

    if (childArray.length > 0) {
      if (allChildrenHaveSubPaths(childArray)) {
        switchChildrenPathsAndKeys = childArray
          .map((child, index) => {
            if (index === 0) {
              defaultChildPath = (
                <Route
                  path={'/*'}
                  element={<Navigate to={child.props.subPath} replace />}
                />
              )
            }

            return [
              child,
              // first child has optional path so as to render by default
              // this is also why we reverse the array below
              // `${child.props.subPath}${index === 0 ? '/*' : ''}`,
              `${child.props.subPath}`,
              child.props.subPath,
            ]
          })
          .reverse()
      } else {
        switchChildrenPathsAndKeys = childArray.map((child, index) => [
          child,
          '',
          index,
        ])
      }
    }
  }

  return (
    <>
      {Header ? (
        <PageHeaderSlot mfeName={mfeName}>
          <HeaderEditingRouteObjectName
            HeaderComponent={Header}
            routeParamsID={objID}
            isInActiveTab
            obj={obj}
            user={currentUser}
            userID={currentUser?.ID}
            path={basePath}
            {...propsWithoutChildren}
          />
        </PageHeaderSlot>
      ) : null}
      {hasToolbar ? (
        <PageToolbarSlot mfeName={mfeName}>
          <div
            id="toolbar-items"
            className="w-full flex-1 flex justify-between"
          >
            <div id="toolbar-left">{toolbarLeft}</div>
            <div id="toolbar-right">{toolbarRight}</div>
          </div>
        </PageToolbarSlot>
      ) : null}
      <PageContentSlot mfeName={mfeName}>
        <Suspense fallback={null}>
          {switchChildrenPathsAndKeys ? (
            <Routes key={`${baseComponentRemountKey}-routes`}>
              {defaultChildPath && defaultChildPath}
              {switchChildrenPathsAndKeys.map(([child, path, key]) => (
                <Route
                  path={path}
                  element={React.cloneElement(child, {
                    key,
                    obj,
                    user: currentUser,
                    navigate,
                    match,
                    location,
                    defaultTileParameters: props.defaultTileParameters,
                    tileToTabPaths: props.tileToTabPaths,
                    ...child.props,
                    styles:
                      (styles || '') + (child.props.styles || '') || undefined,
                    path: basePath,
                    isInActiveTab: true,
                  })}
                />
              ))}
            </Routes>
          ) : typeof children === 'function' ? (
            children({ ...propsWithoutChildren, obj, user: currentUser })
          ) : null}
        </Suspense>
      </PageContentSlot>
    </>
  )
}

function normalizeNavConfigProp({ navConfig, possibleNavItems }) {
  return navConfig ?? possibleNavItems
}

function allChildrenHaveSubPaths(childArray) {
  return childArray.every((child) => child.props.subPath !== undefined)
}

/**
 * @param {Object} props
 * @param {any} [props.logger]
 * @param {any} [props.Header]
 * @param {string} [props.additionalAccessCheckAction] - additional access check for page
 * @param {any} [props.children] - one or more Page.Content as children
 * @param {any} [props.defaultTileParameters] - tile parameters to pass down to all Page.Content components with a tile (and not parameters) prop
 * @param {boolean} [props.fetchPermissions] - whether or not to append permissions:actions to fields array (default true)
 * @param {Array<string>} [props.fields] - fields to fetch on the detail object (objCode prop plus :ID from path prop)
 * @param {boolean} [props.hasToolbar] - whether to render the active tabs and toolbar
 * @param {string} [props.mfeName] - name of the MFE rendering the Page component
 * @param {object} [props.navConfig] - an object with string:NavObject key value pairs, used to be known as possibleNavItems
 * @param {string} [props.objCode] - when used with :ID from the path, the detail object will be fetched and be set as obj on state and in callbacks
 * @param {string} props.path - react-router Route string (passed down from Routes.js)
 * @param {object} [props.possibleNavItems] - deprecated prop, should be converted to navConfig
 * @param {string} [props.styles] - styles to apply to all shims this Page controls
 * @param {object} [props.tileToTabPaths]
 * @param {any} [props.toolbarLeft]
 * @param {any} [props.toolbarRight]
 * @returns {JSX.Element}
 */
export function Page({ logger, ...remainingProps }) {
  return (
    <ErrorBoundary logger={logger}>
      <InnerPage {...remainingProps} />
    </ErrorBoundary>
  )
}

Page.defaultProps = {
  fetchPermissions: true,
  hasToolbar: true,
}

Page.propTypes = {
  Header: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  /** additional access check for page */
  additionalAccessCheckAction: PropTypes.string,
  /** one or more Page.Content as children */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** tile parameters to pass down to all Page.Content components with a tile (and not parameters) prop */
  defaultTileParameters: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  /** whether or not to append permissions:actions to fields array (default true) */
  fetchPermissions: PropTypes.bool,
  /** fields to fetch on the detail object (objCode prop plus :ID from path prop) */
  fields: PropTypes.arrayOf(PropTypes.string),
  /** whether to render the active tabs and toolbar */
  hasToolbar: PropTypes.bool,
  /** name of the MFE rendering the Page component*/
  mfeName: PropTypes.string,
  navConfig: PropTypes.object,
  /** when used with :ID from the path, the detail object will be fetched and be set as obj on state and in callbacks */
  objCode: PropTypes.string,
  /** react-router Route string (passed down from Routes.js) */
  path: PropTypes.string.isRequired,
  possibleNavItems: PropTypes.object,
  /** styles to apply to all shims this Page controls */
  styles: PropTypes.string,
  tileToTabPaths: PropTypes.object,
  toolbarLeft: PropTypes.node,
  toolbarRight: PropTypes.node,
}

Page.Content = PageContent
